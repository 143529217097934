import React, {useState} from 'react';
import {Grid, Typography} from '@mui/material';
import MappingCalculator from "./mappingCalculator";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PhotoLoader from "./PhotoLoader";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';


const darkTheme = createTheme({
    palette: {
        mode: 'dark', // Detta sätter dark mode för Material-UI komponenter
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#ADD8E6', // Light blue
                },
            },
        },
    },
});


const App: React.FC = () => {
  const [imgdata1, setImageData1] = useState<[Uint8Array, number, number] | null>(null);
  const [imgdata2, setImageData2] = useState<[Uint8Array, number, number] | null>(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
        setOpen(false);
  };


  return (
      <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ marginBottom: '16px' }}>
              <Grid item>
                  <Typography variant="h4">
                      PIXELMAP Framework - dense correspondence between photos
                  </Typography>
              </Grid>
              <Grid item>
                  <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                      About
                  </Button>
              </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose}>
              <DialogTitle>About This Project</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      <ul>
                          <li>
                              This interactive website showcases the PIXELMAP framework, a collection of algorithms that
                              does dense image correspondence.
                          </li>
                          <li>
                              The paper describing the algorithms can be found &nbsp;
                              <a href="https://doi.org/10.36227/techrxiv.173749998.89779329/v1" target="_blank"
                                 rel="noopener noreferrer">here</a>.
                          </li>
                          <li>
                              The open source repository for the algorithm can be found in &nbsp;
                              <a href="https://github.com/d4per/pixelmap" target="_blank"
                                 rel="noopener noreferrer">Github</a>.
                          </li>
                          <li>
                              All calculations happen in your browser; no images are ever sent to a server.
                          </li>
                          <li>
                              You can experiment freely with image correspondence since everything is computed locally
                              on your browser.
                          </li>
                      </ul>
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose} color="primary">
                      Close
                  </Button>
              </DialogActions>
          </Dialog>
          <PhotoLoader
              onImageData1Change={setImageData1}
              onImageData2Change={setImageData2}
          />
          <MappingCalculator imgdata1={imgdata1} imgdata2={imgdata2}></MappingCalculator>
      </ThemeProvider>
  );
};

export default App;
