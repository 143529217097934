import React, { useRef, useEffect, useState } from 'react';
import { Slider, Typography, Box } from '@mui/material'; // Importing the Slider component from Material-UI


interface Photo {
    imgData: Uint8ClampedArray;
    width: number;
    height: number;
    getRGB: (x: number, y: number) => [number, number, number];
}

function photoFromMap(map: Map<string, any>): Photo {
    const imgBytes: Uint8ClampedArray = map.get("img_data") as Uint8ClampedArray;
    const width: number = map.get("width") as number;
    const height: number = map.get("height") as number;

    return {
        imgData: imgBytes,
        width: width,
        height: height,
        getRGB: (x: number, y: number) => {
            const index = (y * width + x) * 4;
            return [imgBytes[index], imgBytes[index + 1], imgBytes[index + 2]];
        },
    }
}

export function photoMappingFromMap(map: Map<string, any>): PhotoMapping {
    const photo1 = photoFromMap(map.get("photo1"));
    const photo2 = photoFromMap(map.get("photo2"));
    const gridWidth = map.get("grid_width");
    const gridHeight = map.get("grid_height");
    const gridData = map.get("grid_data");
    return new PhotoMapping(photo1, photo2, gridWidth, gridHeight, gridData);
}

export class PhotoMapping {
    photo1: Photo;
    photo2: Photo;
    gridWidth: number;
    gridHeight: number;
    mapData: Uint16Array;

    constructor(photo1: Photo, photo2: Photo, gridWidth: number, gridHeight: number, mapData: Uint16Array) {
        this.photo1 = photo1;
        this.photo2 = photo2;
        this.gridWidth = gridWidth;
        this.gridHeight = gridHeight;
        this.mapData = mapData;
    }

    setPoint(x1: number, y1: number, x2: number, y2: number) {
        const index = (y1 * this.gridWidth + x1) * 2;
        this.mapData[index] = Math.min(Math.max(x2, 0), 65535); // Clamping within Uint16 bounds
        this.mapData[index + 1] = Math.min(Math.max(y2, 0), 65535);
    }

    getPoint(x1: number, y1: number): [number, number] {
        const index = (y1 * this.gridWidth + x1) * 2;
        if (index >= this.mapData.length) {
            return [65535, 65535]; // Represent "NaN" as 65535
        } else {
            return [this.mapData[index], this.mapData[index + 1]];
        }
    }

    interpolatePhoto(interpolationValue: number, pointSize: number): Photo {
        //interpolationValue = Math.min(Math.max(interpolationValue, 0), 1);
        const h = this.gridHeight;
        const w = this.gridWidth;
        const grid_cell_size = Math.floor(this.photo1.width / (w - 1));

        const result_width = w * 2;
        const result_offset = Math.floor((result_width - w) / 2);
        const interpolatedImgData = new Uint8ClampedArray(result_width * h * 4);
        interpolatedImgData.fill(0);

        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                const [x1, y1] = this.getPoint(x, y);
                if(x1 == 65535) {
                    continue;
                }

                const xInterpolated = x1 * (1.0 - interpolationValue) + x * grid_cell_size * interpolationValue;
                const yInterpolated = y1 * (1.0 - interpolationValue) + y * grid_cell_size * interpolationValue;

                const xx1 = Math.floor(xInterpolated / grid_cell_size) + result_offset;
                const yy1 = Math.floor(yInterpolated / grid_cell_size);
                if(xx1 < 0 || xx1 >= result_width) {
                    continue;
                }

                const [r, g, b] = this.photo1.getRGB(x * grid_cell_size, y * grid_cell_size);

                for (let ye = 0; ye < pointSize; ye++) {
                    let yyy = ye + yy1;
                    if(yyy < h) {
                        for (let xe = 0; xe < pointSize; xe++) {
                            let xxx = xe + xx1;
                            if (xxx < result_width) {
                                const index = (yyy * result_width + xxx) * 4;
                                interpolatedImgData[index] = r;
                                interpolatedImgData[index + 1] = g;
                                interpolatedImgData[index + 2] = b;
                                interpolatedImgData[index + 3] = 255;
                            }
                        }
                    }
                }
            }
        }

        return {
            imgData: interpolatedImgData,
            width: result_width,
            height: h,
            getRGB: (x: number, y: number) => {
                const index = (y * result_width + x) * 4;
                return [interpolatedImgData[index], interpolatedImgData[index + 1], interpolatedImgData[index + 2]];
            },
        };
    }
}

interface PhotoInterpolationProps {
    photoMapping: PhotoMapping
}
const PhotoInterpolation: React.FC<PhotoInterpolationProps> = ({ photoMapping }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [interpolationValue, setInterpolationValue] = useState<number>(0.5);
    const [pointSize, setPointSize] = useState<number>(2);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        // Interpolate between the two photos using the current interpolationValue
        const interpolatedPhoto = photoMapping.interpolatePhoto(interpolationValue, pointSize);

        let w = interpolatedPhoto.width;
        let h = interpolatedPhoto.height;
        let scale = Math.floor(window.innerWidth / w);
        scale = scale < 1 ? 1 : scale;
        canvas.width =  w * scale;
        canvas.height = h * scale;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Put interpolated image data into canvas
        const imageData = new ImageData(interpolatedPhoto.imgData, interpolatedPhoto.width, interpolatedPhoto.height);

        //console.log("scale "+scale+"  "+(w*scale));
        createImageBitmap(imageData).then(renderer =>
            ctx.drawImage(renderer, 0,0, w * scale, h * scale)
        );

    }, [interpolationValue, photoMapping, pointSize]); // Re-run effect when interpolationValue or photoMapping changes

    // Handler for the slider value change
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setInterpolationValue(newValue as number); // Update the interpolation value
    };

    const handlePointSizeChange = (event: Event, newValue: number | number[]) => {
        setPointSize(newValue as number);
    };

    return (
        <div>
            {/* Flexbox to align sliders on the same row */}
            <Box display="flex" alignItems="center" gap={4} mb={2}>
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Interpolation Value (Original photos at 0.0 and 1.0)
                    </Typography>
                    <Slider
                        value={interpolationValue}
                        min={-3.0}
                        max={4.0}
                        step={0.01}
                        onChange={handleSliderChange}
                        aria-labelledby="interpolation-slider"
                        valueLabelDisplay="auto"
                        sx={{ width: 400 }} // Set slider width
                    />
                </Box>

                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Point Size
                    </Typography>
                    <Slider
                        value={pointSize}
                        min={1}
                        max={5}
                        step={1}
                        onChange={handlePointSizeChange}
                        aria-labelledby="pointsize-slider"
                        valueLabelDisplay="auto"
                        sx={{ width: 200 }} // Set slider width
                    />
                </Box>
            </Box>
            {/* Canvas where the interpolated photo will be drawn */}
            <canvas ref={canvasRef} width={500} height={500} />

        </div>
    );
};

export default PhotoInterpolation;
